import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

root.render(
  //<React.StrictMode>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
 // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
